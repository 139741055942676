import * as Sentry from '@sentry/nuxt'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://ade88def07d7ec58a3151e7adcfa2a39@o407034.ingest.us.sentry.io/4507883486445568',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})
